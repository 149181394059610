<template>
  <button
    class="btn btn-loading"
    :class="btnClass"
    disabled
  >
    <div
      class="spinner-border m-2"
      role="status"
    />
  </button>
</template>

<script>
export default {
    props: {
        loading: {type: Boolean, default: false},
        btnClass: {type: String, default: 'btn-primary'}
    },
    mounted(){ console.log(`Class btn loading ${this.btnClass}`)},
    data(){
        return {

        }
    },
    computed: {

    }

}
</script>

<style lang="scss" scoped>
.btn-loading{
    min-height: 45px;
    padding: 0px;
    .spinner-border{
    color: white;
}
}

</style>